body {
  --kt-toolbar-height: 31px;
  --kt-toolbar-height-tablet-and-mobile: 55px
}

form label{
  font-weight: 900;
}
.footer{
  margin-top: 15px;
}