.offcanvas {
    background-color: #f8f9fa;
    border-radius: 10px;
  }
  .offcanvas-header {
      border-top-left-radius:10px;
    background-color: #007bff;
    color: white;
    border-bottom: 1px solid #ddd;
  }
  .offcanvas-body {
    padding: 20px;
  }
  .info-container {
    background-color: #e9ecef;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  .info-container .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .info-container h6 {
    margin-bottom: 0;
    color: #495057;
    font-weight: 600;
  }
  .info-container .name {
    font-size: 1.2rem;
    color: #343a40;
    font-weight: bold;
  }
  .info-container .amount {
    font-size: 1.5rem;
    color: #28a745;
    font-weight: 600;
  }
  .summary-section {
    text-align: center;
    background-color: #d4edda;
    color: #155724;
    padding: 15px;
    border-radius: 10px;
    margin-top: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .summary-section h4 {
    font-weight: bold;
  }
  .summary-section p {
    margin: 0;
  }
  .tagline {
    text-align: center;
    font-size: 0.9rem;
    color: #6c757d;
    margin-top: 15px;
    font-style: italic;
  }

  .offcanvas-title{
    color: white;
    font-size: 1.4rem;
  }